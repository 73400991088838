.prayer {
    background-color: #130E07; /* Dark background color */
    color: #ffffff; /* Text color */
    text-align: center;
    padding: 2rem;
    font-family: sans-serif;
  }
  .prayer h2 {
    margin-bottom: 3rem;
    font-size: 3rem;
  }
  
  .prayer p {
    font-size: 1.2rem;
    margin-bottom: 3rem;
    color: #59544D;
  }
  
  .prayer .button {
    background-color: #ffffff; /* Button background color */
    color: #000000; /* Button text color */
    padding: 1rem 2rem;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-bottom: 3rem;
  }
  
  .prayer .button:hover {
    background-color: #f0c014; /* Darker button color on hover */
  }
  @media (max-width: 768px) {
    .prayer {
      padding: 1.5rem;
    }
  
    .prayer h2 {
      font-size: 2.5rem;
      margin-bottom: 2.5rem;
    }
  
    .prayer p {
      font-size: 1rem;
      margin-bottom: 2.5rem;
    }
  
    .prayer .button {
      font-size: 0.9rem;
      padding: 0.8rem 1.5rem;
      margin-bottom: 2.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .prayer {
      padding: 1rem;
    }
  
    .prayer h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  
    .prayer p {
      font-size: 0.9rem;
      margin-bottom: 2rem;
    }
  
    .prayer .button {
      font-size: 0.8rem;
      padding: 0.6rem 1.2rem;
      margin-bottom: 2rem;
    }
  }
  
  @media (max-width: 280px) {
    .prayer {
      padding: 0.5rem;
    }
  
    .prayer h2 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  
    .prayer p {
      font-size: 0.8rem;
      margin-bottom: 1.5rem;
    }
  
    .prayer .button {
      font-size: 0.7rem;
      padding: 0.4rem 1rem;
      margin-bottom: 1.5rem;
    }
  }