/* SwiperComponent.css */
/* SwiperComponent.css */
.home-events .event {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  }
  
  .home-events .event-details {
    text-align: center; /* Center the text */
    max-width: 425px;
    margin: 1rem;
  }
  
  .home-events .event-details h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .home-events .event-details p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  
  .home-events .event-image-container {
    width: 100%;
    max-width: 425px;
    margin: 1rem;
    flex: 1 1 auto;
    display: flex;
    justify-content: center; /* Center the image */
  }
  
  .home-events .event-image {
    width: 100%;
    height: auto;
    max-height: 300px; /* Limit the height to ensure images fit well */
    object-fit: contain;
  }
  
  .home-events .swiper-container {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  .home-events .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .home-events .event {
      flex-direction: column;
      align-items: center;
    }
  
    .home-events .event-details {
      max-width: 100%;
      margin: 1rem 0;
    }
  
    .home-events .event-image-container {
      max-width: 100%;
    }
  }
  