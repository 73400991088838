* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Arial", sans-serif;
}
.contact {
  background-color: #faf4ef;
  padding-top: 0px;
}
.contact-hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  color: #fff;
  padding: 2rem;
}
.contact-hero-content {
  z-index: 2;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem; /* Add margin to separate from buttons */
  padding: 0 1rem; /* Add padding for smaller screens */
  flex: 1; /* Ensure it takes up available space */
  display: flex; /* Use flex to center text */
  flex-direction: column; /* Ensure vertical alignment */
  justify-content: center; /* Center text vertically */
}
.contact-hero-buttons {
  z-index: 2;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
  justify-content: center;
}
.btn {
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}
@media (max-width: 768px) {
  .contact-hero-content {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .contact-hero-buttons {
    gap: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
  }
  .contact-hero {
    padding: 2rem;
  }
  .contact-hero-buttons .btn {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .contact-hero-content {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .contact-hero-buttons .btn {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
  .contact-hero {
    padding: 1rem;
  }
  .contact-hero-buttons {
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
  }
}
.contact-form-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 2rem;
}
.contact-form-section h2 {
  font-size: 56px;
  margin-bottom: 1rem;
  font: work-sans;
}
.contact-form-section p {
  font-size: 32px;
  margin-bottom: 1.5rem;
}
.contact-form {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.form-group {
  display: flex;
  flex-direction: column;
}
.form-group label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.form-group input,
.form-group textarea {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}
textarea {
  resize: vertical;
  min-height: 100px;
}
.btn-submit {
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #d3a357;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.btn-submit:hover {
  background-color: #b3862c;
}
.contact-form-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}


.form-and-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.contact-form-container {
  flex: 1;
  max-width: 600px;
}

.contact-info {
  flex: 1;
  max-width: 800px; /* Increase max-width to make the section wider */
  margin: 3rem auto; /* Center the section horizontally with auto margins */
  text-align: center; /* Center the text within the section */
}

.contact-info h3 {
  font-size: 24px;
  margin-bottom: 1rem;
}

.contact-info p {
  font-size: 18px;
  margin-bottom: 1rem;
}

.map-container {
  margin-top: 1rem;
  display: flex;
  justify-content: center; /* Center the map within the container */
}