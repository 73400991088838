
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Arial", sans-serif;
  
}
.bulletin-hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  position: relative;
  color: #fff;
  text-align: center;
}
 .hero-content {
  z-index: 2;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  padding: 0 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bulletin-container {
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .section h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .event {
    margin-bottom: 10px;
  }
  
  .event-field {
    margin-bottom: 10px;
  }
  
  .event-field strong {
    display: block;
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .event-field p {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    margin: 0;
  }
  
  .error {
    color: red;
    margin-bottom: 20px;
  }
  