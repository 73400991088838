* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #faf4ef;
}

.home {
  padding-top: 0px; 
}

.hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem;
  position: relative;
  color: #fff;
}

.hero-content {
  z-index: 2;
  text-align: left;
  font-size: 2.5rem;
  margin-bottom: -4rem; 
}

.hero-buttons {
  z-index: 2;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem; 
  justify-content: right;
}

.hero-overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 2rem;
  z-index: 3;
}

.overlay-text {
  position: absolute;
  top: 20%;
  left: 2rem;
  color: #FFD700; 
  font-size: 3rem;
}

.btn-ministries {
  padding: 1rem 2rem; 
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-weight: bold;
  font-size: 1.2rem; 
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
}

.btn-ministries.secondary {
  background: rgba(255, 255, 255, 0.8);
}

.btn-ministries:hover {
  background: #f0f0f0;
}


.ministries-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #faf4ef;
}

.ministries-list h1 {
  font-size: 2.5em;
  color: #D18900;
  margin-top: 50px;
  margin-bottom: 100px;
}

.ministries-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
  width: 100%;
  margin-bottom: 75px;
}

.ministry-item {
  background-color: white;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px; /* Ensure the items have a fixed width */
}

.ministry-item img {
  width: 100%;
  height: auto;
}

.ministry-item h2 {
  font-size: 1.5em;
  margin: 10px 0;
  color: #333;
}

.ministry-item p {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

.ministry-item a {
  text-decoration: none;
  color: inherit;
}

.ministry-item a:hover {
  color: #D18900;
}
@media (max-width: 768px) {
  .hero-content {
    font-size: 2rem;
  }
  .hero-buttons {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .btn-ministries {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
  .hero {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .hero-content {
    font-size: 1.5rem;
  }
  .hero-buttons {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .btn-ministries {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }
  .hero {
    padding: 0.5rem;
  }
}

@media (max-width: 280px) {
  .hero-content {
    font-size: 1.2rem;
  }
  .hero-buttons {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .btn-ministries {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }
  .hero {
    padding: 0.3rem;
  }
}

@media (max-width: 768px) {
  .ministries-list h1 {
    font-size: 2rem;
  }
  .ministries-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  }
  .ministry-item {
    width: 200px;
  }
}

@media (max-width: 480px) {
  .ministries-list h1 {
    font-size: 1.5rem;
  }
  .ministries-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .ministry-item {
    width: 100%;
  }
}

@media (max-width: 280px) {
  .ministries-list h1 {
    font-size: 1.2rem;
  }
  .ministries-grid {
    grid-template-columns: 1fr;
    gap: 5px;
  }
  .ministry-item {
    width: 100%;
  }
}