* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
}

.home {
  padding-top: 0px;
  max-width: 100%;
}

.home .hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  position: relative;
  color: #fff;
  text-align: center;
}

.home .hero-content {
  z-index: 2;
  text-align: center;
  font-size: 3rem; /* Adjusted font size */
  margin-bottom: 2rem; /* Add margin to separate from buttons */
  padding: 0 1rem; /* Add padding for smaller screens */
  flex: 1; /* Ensure it takes up available space */
  display: flex; /* Use flex to center text */
  flex-direction: column; /* Ensure vertical alignment */
  justify-content: center; /* Center text vertically */
}

.home .hero-buttons {
  z-index: 2;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem; 
  justify-content: center; /* Center buttons horizontally */
}

.home .btn {
  padding: 1rem 2rem; 
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-weight: bold;
  font-size: 1.2rem; 
  cursor: pointer;
  transition: background-color 0.3s;
}

.home .btn.secondary {
  background: rgba(255, 255, 255, 0.8);
}

.home .btn:hover {
  background: #f0f0f0;
}
.quote {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #faf4ef;
  color: #6b4f3e;
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.quote p {
  max-width: 800px; /* text width */
  margin: 0 auto;
  position: relative;
}
@media (max-width: 768px) {
  .quote {
    font-size: 1.2rem;
    height: auto;
    padding: 1rem;
  }

  .quote p {
    padding: 0 1rem;
  }
}

.announcements {
  background-color: #f7f7f7; /* Light background color */
  color: #a97942;
  text-align: center;
  padding: 1rem;
}

.announcements h2 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  animation: marquee 50s linear infinite; /* Adjusted duration for a slower speed */
  margin-bottom: 3rem;
}

.marquee span {
  display: inline-block;
  padding-right: 100%;
  animation: marquee 50s linear infinite; /* Adjusted duration for a slower speed */
}

@keyframes marquee {
  0% {
    transform: translateX(-100%); /* Start from the left */
  }
  100% {
    transform: translateX(100%); /* Move to the right */
  }
}

.announcements .button {
  background-color: #000000; /* Button background color */
  color: #FFD700; /* Button text color */
  padding: 1rem 2rem;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
  
}
.announcements .button:hover {
  background-color: #5a4233; /* Darker button color on hover */
}
@media (max-width: 768px) {
  .announcements h2 {
    font-size: 1.5rem;
  }

  .marquee {
    font-size: 0.9rem;
  }
  .announcements .button {
    font-size: 0.9rem;
    padding: 0.8rem 1.5rem;
  }
}
@media (max-width: 480px) {
  .announcements h2 {
    font-size: 1.2rem;
  }

  .marquee {
    font-size: 0.8rem;
  }

  .announcements .button {
    font-size: 0.8rem;
    padding: 0.6rem 1.2rem;
  }
}
@media (max-width: 280px) {
  .announcements h2 {
    font-size: 1rem;
  }

  .marquee {
    font-size: 0.7rem;
  }

  .announcements .button {
    font-size: 0.7rem;
    padding: 0.4rem 0.8rem;
  }
}

.bulletin {
  background-color: #f7f7f7; /* Light beige background */
  color: #a97942; /* Darker text color */
  text-align: center;
  padding: 2rem;
}

.bulletin h2 {
  margin-bottom: 4rem;
  font-size: 2rem;
}

.bulletin p {
  margin-bottom: 5rem;
  font-size: 1.1rem;
}

.bulletin .button {
  background-color: #000000; /* Button background color */
  color: #FFD700; /* Button text color */
  padding: 1rem 2rem;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.bulletin .btn:hover {
  background-color: #5a4233; /* Darker button color on hover */
}
@media (max-width: 768px) {
  .bulletin h2 {
    font-size: 1.5rem;
  }

  .bulletin p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .bulletin .button {
    font-size: 0.9rem;
    padding: 0.8rem 1.5rem;
  }
}
@media (max-width: 280px) {
  .bulletin h2 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .bulletin p {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }

  .bulletin .button {
    font-size: 0.7rem;
    padding: 0.4rem 0.8rem;
  }
}
.services {
  background-color: #1E1E1E;
  color: #fff;
  padding: 2rem;
  text-align: left;
  position: relative;
  overflow: hidden; /* Prevent images from crossing the section boundaries */
  max-width: 1700px;
  width: 100%;
  height: 100vh;
  margin: 0 auto; /* Center the section */
}

.services .service-info {
  display: flex;
  justify-content: space-between; /* Align items to the ends */
  gap: 2rem;
  position: relative; /* Ensure this is the relative positioning context */
}

.service-text {
  max-width: 600px;
  flex: 1;
  margin-left: 3rem;
  margin-right: 3rem; /* Add right margin to balance the layout */
}
.service-text h1{
  font-size: 40px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: #A87C3E;
}
.service-text h2 {
  font-size: 24px;
  margin-bottom: 0.5rem;
  color: #EBDEC7;
}

.service-text p {
  font-size: 28px;
  margin-bottom: 2rem;
}

.service-images-container {
  position: relative;
  max-width: 600px;
  flex: 1;
  display: flex;
  flex-direction: nowrap;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 8rem;
  margin-right: 3rem;
}

.service-images-container img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.service-image-1 {
  position: relative;
  z-index: 2;
}

.service-image-2 {
  position: relative;
  z-index: 1;
  translate: (50%, 50%);
}

/* Responsive adjustments */
/* Responsive adjustments for the services section */
@media (max-width: 1200px) {
  .services .service-info {
    flex-direction: column;
    align-items: center;
    text-align: center; /* Center align the text for smaller screens */
  }

  .service-text {
    margin-left: 0; /* Remove left margin on smaller screens */
   
  }

  .service-images-container {
    justify-content: center; /* Center images on smaller screens */
    width: 100%;
    height: auto;
    flex-direction: row; /* Align images horizontally */
    align-items: center;
    gap: 1rem;
  }

  .service-images-container img {
    width: 45%; /* Adjust width to fit two images side by side */
    height: auto;
  }

  .service-text h1, .service-text h2 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .service-text p {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 768px) {
  .home .hero-content {
    font-size: 1.5rem;
  }

  .home .hero-buttons .btn {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }

  .services h1, .services h2 {
    font-size: 1.5rem;
  }

  .service-text p {
    font-size: 1.2rem;
  }

  .service-images-container {
    flex-direction: row; /* Align images horizontally */
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
  }

  .service-images-container img {
    width: 45%; /* Adjust width to fit two images side by side */
    height: auto;
  }
}
.live-services {
  padding: 20px;
  text-align: center;
  background-color: #faf4ef;
}

.live-services h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.live-stream {
  display: flex;
  justify-content: center;
  align-items: center;
}

.live-stream iframe {
  width: 100%;
  max-width: 560px;
  height: 315px;
  border: none;
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .live-services h2 {
    font-size: 1.5rem;
  }

  .live-stream iframe {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .live-services h2 {
    font-size: 1.2rem;
  }

  .live-stream iframe {
    width: 100%;
    height: auto;
  }
}
.home-events {
  background-color: #faf4ef;
  color: #6b4f3e;
  padding: 2rem;
  background-image: url('../assets/wavy_background.png');
  background-size: cover; /* background image covers the entire section */
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh; /* Ensures the section takes at least the full viewport height */
  width: 100%;
}

.home-events h2 {
  font-size: 40px;
  margin-bottom: 1rem;
  text-align: center;
  color: #A87C3E;
}
@media (max-width: 768px) {
  .home-events {
    padding: 1rem;
    min-height: 50vh; /* Adjust height for smaller screens */
  }

  .home-events h2 {
    font-size: 1.5rem;
  }

  .home-events p {
    font-size: 1rem;
  }
}

