
.header {
  background: transparent;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}

.navbar-brand {
  margin-left: 1rem;
}

.navbar-brand a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
  margin-right: 2rem;
}

.nav-links li {
  margin-left: 2rem;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

/* Hamburger menu styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: all 0.3s;
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px; /* Adjust based on your navbar height */
    left: 0;
    background-color: #333; /* Background color for mobile menu */
    text-align: center;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 1rem 0;
  }

  .hamburger {
    display: flex;
  }
}
