* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Style for the main content area */
  .podcast-detail main {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally */
    justify-content: center; /* Centers content vertically */
    min-height: 100vh; /* Ensures the main content takes at least the full height of the viewport */
    padding: 20px; /* Optional padding around the content */
    background-color: #cfba96; /* Light background color */
  }
  
  /* Style for the podcast title */
  .podcast-detail h1 {
    font-size: 2rem; /* Larger font size for the title */
    margin-bottom: 20px; /* Space between the title and the video */
    color: #333; /* Darker text color */
    text-align: center; /* Center the title text */
  }
  
  /* Style for the video iframe */
  .podcast-detail iframe {
    max-width: 100%; /* Responsive width */
    height: auto; /* Responsive height */
    aspect-ratio: 16/9; /* Ensures the video maintains a 16:9 aspect ratio */
    border-radius: 8px; /* Optional rounded corners for the video */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for a nice effect */
  }
  
  /* Optional: Footer styling */
  .podcast-detail footer {
    margin-top: 40px; /* Space between the content and the footer */
    text-align: center; /* Center footer text */
    color: #666; /* Slightly lighter text color */
    padding: 20px; /* Padding around the footer content */
    background-color: #f1f1f1; /* Light background color for the footer */
  }