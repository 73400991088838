.main {
    padding: 20px;
    height: auto;
    overflow: hidden; /* Prevents overflowing of the main section */
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.title {
    text-align: center;
    font-size: 40px;
    margin-bottom: 20px;
    color: #A87C3E;
}
