* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
}
.podcasts-page {
  padding-top: 0px;
}

.podcasts-hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
  text-align: center;
  margin-bottom: 2rem; /* Adds space below the hero section */
}
.podcasts-hero-content {
  z-index: 2;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 2rem;
  padding: 0 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.podcasts-hero-buttons {
  z-index: 2;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
  justify-content: center;
}
.podcasts-hero-buttons{
  z-index: 2;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
  justify-content: center;
  text-decoration: none;
}
.btn-podcasts{
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 2rem;
}
.btn-podcasts-secondary{
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
}


.podcasts-map h1 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.podcasts-map {
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  background-color: #f4ede1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}

.podcast-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f7f2e9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.podcast-thumbnail {
  width: 120px;
  height: auto;
  margin-right: 20px;
  border-radius: 8px;
}

.podcast-info {
  display: flex;
  flex-direction: column;
}

.podcast-info h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.podcast-info h3 {
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
}
.podcast-info a {
  text-decoration: none;
}

/* Applying hover color change to the link */
.podcast-info a:hover h2 {
  color: #800080; /* Hover color for the title */
}
@media only screen and (max-width: 600px) {
  .podcasts-hero-content {
    font-size: 2rem;
  }
  .podcast-hero-buttons .btn-podcasts {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
  .podcasts-page h1 {
    font-size: 1.8rem;
    padding-top: 50px;
  }

  .podcasts-map {
    padding: 15px;
    margin: 15px auto;
    max-width: 100%;
  }

  .podcast-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
  }

  .podcast-thumbnail {
    width: 100%;
    margin-bottom: 15px;
  }

  .podcast-info h2 {
    font-size: 1.3rem;
  }

  .podcast-info h3 {
    font-size: 0.95rem;
  }
}

/* For screens up to 400px */
@media  (max-width: 400px) {
  .podcasts-hero-content {
    font-size: 1.8rem;
  }
  .podcast-hero-buttons .btn-podcasts {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
  .podcasts-page h1 {
    font-size: 1.6rem;
    padding-top: 45px;
  }

  .podcast-info h2 {
    font-size: 1.2rem;
  }

  .podcast-info h3 {
    font-size: 0.9rem;
  }
}

/* For screens up to 280px */
@media (max-width: 280px) {
  .podcasts-hero-content {
    font-size: 1.6rem;
  }
  .podcast-hero-buttons .btn-podcasts {
    padding: 0.4rem .8rem;
    font-size: .7rem;
  }
  .podcasts-page h1 {
    font-size: 1.4rem;
    padding-top: 40px;
  }

  .podcasts-map {
    padding: 10px;
    margin: 10px auto;
  }

  .podcast-item {
    padding: 10px;
  }

  .podcast-info h2 {
    font-size: 1.1rem;
  }

  .podcast-info h3 {
    font-size: 0.85rem;
  }
}
