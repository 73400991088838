
.footer {
    display: flex;
    flex-direction: column;
    background: url('../assets/footer.png') no-repeat center center;
    background-size: cover;
    color: white;
    padding: 20px;
    min-height: 400px;
  }
  
  .footer-left {
    flex: 1;
  }
  
  .footer-middle {
    display: flex;
    justify-content: space-around;
    flex: 2;
  }
  
  .footer-section {
    list-style: none;
    text-decoration: none;
  }
  .footer-section a {
    text-decoration: none;
    color: inherit; /* This will inherit the text color of the parent element */
  }
  
  .footer-section a:hover {
    text-decoration: underline; /* Optional: add underline on hover if needed */
  }
  .footer-section h3 {
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section li {
    margin: 5px 0;
  }
  
  .footer-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
  }
  
  .social-icons {
    display: flex;
    gap: 10px;
  }
  
  .fab {
    font-size: 24px;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .footer-bottom ul {
    display: flex;
    gap: 20px;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-bottom li {
    cursor: pointer;
  }
  .social-icons a {
    outline: none;
  }
  
  .social-icons a:focus {
    outline: none;
    box-shadow: none; /* This ensures there's no shadow around the icon when focused */
  }