* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
}

.announcements-page {
  padding-top: 0px;
  max-width: 100%;
}

.announcements-page .hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
  text-align: center;
}

.announcements-page .hero-content {
  z-index: 2;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  padding: 0 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.announcements-page .hero-buttons {
  z-index: 2;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
  justify-content: center;
}

.announcements-page .btn {
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

@media (max-width: 768px) {
  .announcements-page .hero-content {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .announcements-page .hero-buttons {
    gap: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .announcements-page .hero-content {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .announcements-page .hero-buttons {
    gap: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 280px) {
  .announcements-page .hero-content {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  .announcements-page .hero-buttons {
    gap: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: center;
  }
}

.announcements-container {
  padding: 2rem;
  background-color: #faf4ef;
  color: #333;
}

.announcements-container h1 {
  margin-bottom: 1rem;
  font-size: 2rem;
  text-align: center;
}

.announcement {
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  padding: 1.5rem;
}

.announcement-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.announcement-date {
  color: #999;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.announcement-body {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.announcement-read-more {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}