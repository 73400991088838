html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
}

.home {
  padding-top: 0px; 
}

.hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  position: relative;
  color: #fff;
  text-align: center;
}

.hero-content {
  z-index: 2;
  text-align: center;
  font-size: 3rem; /* Adjusted font size */
  margin-bottom: 2rem; /* Add margin to separate from buttons */
  padding: 0 1rem; /* Add padding for smaller screens */
  flex: 1; /* Ensure it takes up available space */
  display: flex; /* Use flex to center text */
  flex-direction: column; /* Ensure vertical alignment */
  justify-content: center; /* Center text vertically */

}

.hero-buttons {
  z-index: 2;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem; 
  justify-content: center; /* Align buttons to the right */
}

.btn {
  padding: 1rem 2rem; 
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-weight: bold;
  font-size: 1.2rem; 
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
}

.btn.secondary {
  background: rgba(255, 255, 255, 0.8);
}

.btn:hover {
  background: #f0f0f0;
}
@media (max-width: 768px) {
  .hero-content {
    font-size: 2rem; /* Adjusted font size for mobile */
    margin-bottom: 1rem;
  }
  .hero-buttons {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: auto; /* Pushes the buttons to the bottom */
    margin-bottom: 1rem;
  }
  .btn {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
  .hero {
    padding: 1rem;
  }
}
@media (max-width: 480px) {
  .hero-content {
    font-size: 1.5rem; /* Adjusted font size for smaller screens */
    margin-bottom: 0.5rem;
  }
  .btn {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }
  .hero {
    padding: 0.5rem;
  }
  .hero-buttons {
    margin-top: auto; /* Ensures the buttons are pushed to the bottom */
    margin-bottom: 1rem;
  }
}
@media (max-width: 280px) {
  .hero-content {
    font-size: 1.2rem; /* Adjusted font size for very small screens */
    margin-bottom: 0.3rem;
  }
  .btn {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }
  .hero {
    padding: 0.3rem;
  }
  .hero-buttons {
    margin-top: auto; /* Ensures the buttons are pushed to the bottom */
    margin-bottom: 0.5rem;
  }
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #faf4ef;
  width: 100%;
  height: auto; /* Changed to auto for better adaptability */
  border-radius: 0;
  box-shadow: none;
  background-image: url('../assets/wavy.png');
  flex-wrap: wrap; /* Ensure content wraps on smaller screens */
}

.image-container {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-top: 50px;
  width: 100%; /* Make the image container full-width on smaller screens */
}

.image-container img {
  border-radius: 40%;
  width: 100%; /* Ensure the image scales with the container */
  max-width: 300px; /* Limit the maximum width */
  height: auto; /* Auto height for maintaining aspect ratio */
  object-fit: cover;
}

.message-text {
  flex: 1;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.message-text h2 {
  color: #D18900;
  font-size: 24px;
  margin-bottom: 10px;
}

.message-text p {
  color: #333;
  font-size: 18px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .image-container {
    margin-right: 0; /* Remove right margin on smaller screens */
    margin-bottom: 20px; /* Add bottom margin for spacing */
  }
  .message {
    padding: 20px; /* Reduce padding on smaller screens */
  }
  .message-text p {
    font-size: 16px; /* Reduce font size on smaller screens */
  }
}

@media (max-width: 480px) {
  .message {
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center align items */
  }
  .message-text p {
    font-size: 14px; /* Further reduce font size on smaller screens */
  }
  .message-text h2 {
    font-size: 20px; /* Adjust heading size */
  }
}
.our-mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #faf4ef;
  color: #6b4f3e;
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;
  height: 65vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/mission-back.png")
}

.our-mission h2 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.our-mission p {
  max-width: 800px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .our-mission {
    padding: 1.5rem;
  }
  .our-mission h2 {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
  }
  .our-mission p {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .our-mission {
    padding: 1rem;
  }
  .our-mission h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .our-mission p {
    font-size: 1.1rem;
  }
}

@media (max-width: 280px) {
  .our-mission {
    padding: 0.5rem;
  }
  .our-mission h2 {
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }
  .our-mission p {
    font-size: 1rem;
  }
}

.who-we-are {
  background-color: #FAFAFA;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  font-family: 'Open Sans', Arial, sans-serif;
}

.who-we-are h2 {
  color: #D18900;
  font-size: 2rem;
  margin-bottom: 10px;
}

.who-we-are h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #4A4A4A;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.who-we-are p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #4A4A4A;
  line-height: 1.6;
  text-align: left;
  margin-left: 10rem;
  margin-right: 10rem;
}

@media (max-width: 768px) {
  .who-we-are h2 {
    font-size: 1.8rem;
  }

  .who-we-are h3 {
    font-size: 1.3rem;
  }

  .who-we-are p {
    font-size: 0.9rem;
    margin-left: 5rem;
    margin-right: 5rem;
    text-align: center; /* Center text for easier reading */
  }
}

@media (max-width: 480px) {
  .who-we-are h2 {
    font-size: 1.5rem;
  }

  .who-we-are h3 {
    font-size: 1.2rem;
  }

  .who-we-are p {
    font-size: 0.85rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (max-width: 320px) {
  .who-we-are h2 {
    font-size: 1.2rem;
  }

  .who-we-are h3 {
    font-size: 1rem;
  }

  .who-we-are p {
    font-size: 0.75rem;
    margin-left: 1rem;
    margin-right: 1rem;
    line-height: 1.4; /* Slightly tighter line height */
  }
}

@media (max-width: 280px) {
  .who-we-are h2 {
    font-size: 1rem;
  }

  .who-we-are h3 {
    font-size: 0.9rem;
  }

  .who-we-are p {
    font-size: 0.7rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    line-height: 1.3;
  }
}

.belief-hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: right; /* Center content vertically */
  align-items: center; /* Align content to the bottom */
  padding: 2rem;
  position: relative;
  color: #fff;
}

.belief-content {
  z-index: 2;
  text-align: right; /* Align text to the right */
  font-size: 2.5rem;
  max-width: 50%; /* Adjust width as needed */
  margin-right: 2rem; /* Add margin to the right */
}
.belief-content h1{
  margin-bottom: 1rem; /* Add space between text elements */
  font-size: 3rem; /* Increase font size */
}
.belief-content p {
  font-size: 1.5rem; /* Increase font size */
  margin-bottom: 1rem; /* Add space between text elements */
}
.btn-belief {
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
}

.btn-belief:hover {
  background: #f0f0f0;
}

@media (max-width: 768px) {
  .belief-content {
    font-size: 2rem;
    max-width: 70%;
    margin-right: 1rem;
  }
  .belief-content h1 {
    font-size: 2.5rem;
  }
  .belief-content p {
    font-size: 1.2rem;
  }
  .btn-belief {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .belief-content {
    font-size: 1.5rem;
    max-width: 90%;
    margin-right: 0.5rem;
    text-align: center; /* Center align text on small screens */
  }
  .belief-content h1 {
    font-size: 2rem;
  }
  .belief-content p {
    font-size: 1rem;
  }
  .btn-belief {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }
}

@media (max-width: 280px) {
  .belief-content {
    font-size: 1.2rem;
    max-width: 100%;
    margin-right: 0.3rem;
    text-align: center; /* Center align text on very small screens */
  }
  .belief-content h1 {
    font-size: 1.5rem;
  }
  .belief-content p {
    font-size: 0.8rem;
  }
  .btn-belief {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }
}