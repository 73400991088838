/* Ministrypage.css */
@import '../styles/Navbar.css'; /* Import the shared navbar styles */
.ministrypage * {
  box-sizing: border-box;
}

.ministrypage {
  font-family: 'Arial', sans-serif;
  background-color: #faf4ef;
  padding-top: 0px;
}

.ministrypage .hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
  text-align: center;
  padding: 2rem;
}

.ministrypage .content {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Adjust the height to accommodate the largest section */
  margin-top: 0px;
  background-size: cover; /* Ensure background image covers the section */
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 0; /* Adjust the top padding to center the content */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ministrypage h1 {
  font-size: 2.5em;
  color: #D18900;
  margin-bottom: 80px;
  text-align: center;
}

.ministrypage .content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.ministrypage .content-left, .ministrypage .content-right {
  padding: 20px;
  border-radius: 10px;
  width: 45%; /* Adjust width according to design */
  min-height: 270px; /* Ensure height matches the design */
  margin-bottom: 20px; /* Add margin for spacing */
}


.ministrypage h2 {
  font-size: 1.5em;
  color: #D18900;
  margin-top: 20px;
}

.ministrypage p {
  font-size: 1em;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
}