* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
  }

    .sermons-page {
        padding-top: 0px;
        max-width: 100%;
    }
    .sermons-page .hero{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        color: #fff;
        text-align: center;
    }
    .sermons-container {
        padding: 20px;
    }
    
    .sermon {
        display: flex;
        flex-direction: row; /* Align items horizontally */
        justify-content: space-between; /* Add space between image and text */
        align-items: center;
        margin-bottom: 20px;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #f9f9f9;
        text-align: left; /* Align text to the left */
        max-width: 900px; /* Restrict the width to a manageable size */
        margin-left: auto;
        margin-right: auto;
    }
    
    .sermon-image-container {
        flex-shrink: 0;
        margin-right: 20px; /* Add space between image and text */
    }
    
    .sermon-image {
        width: 120px;
        height: auto;
        border-radius: 5px;
    }
    
    .sermon-details {
        flex-grow: 1;
    }
    
    .sermon-title {
        font-size: 1.4em; /* Increase the font size */
        margin-bottom: 10px;
    }
    
    .sermon-date,
    .sermon-passage {
        margin: 5px 0;
        font-size: 1em; /* Increase the font size */
    }
    
    .watch-button {
        display: inline-block;
        padding: 12px 24px; /* Increase padding */
        margin-top: 10px;
        background-color: #ffcc00;
        color: #000;
        text-decoration: none;
        border-radius: 5px;
        font-weight: bold;
        font-size: 1.1em; /* Increase font size */
    }
    
    .watch-button:hover {
        background-color: #e6b800;
    }
    
    @media (max-width: 768px) {
        .sermon {
            flex-direction: column;
            text-align: center;
        }
    
        .sermon-image-container {
            margin-right: 0;
            margin-bottom: 15px; /* Add spacing for small screens */
        }
    
        .sermon-image {
            width: 100%; /* Image takes up full width */
            max-width: 200px; /* Limit the maximum size */
        }
    
        .sermon-title {
            font-size: 1.3em; /* Adjust font size for readability */
        }
    
        .sermon-date,
        .sermon-passage {
            font-size: 0.95em; /* Adjust font size */
        }
    }
    
    @media (max-width: 480px) {
        .sermon-image {
            max-width: 150px;
        }
    
        .sermon-title {
            font-size: 1.2em;
        }
    
        .sermon-date,
        .sermon-passage {
            font-size: 0.9em;
        }
    
        .watch-button {
            font-size: 1em;
            padding: 10px 20px;
        }
    }
    
    @media (max-width: 320px) {
        .sermon-image {
            max-width: 120px;
        }
    
        .sermon-title {
            font-size: 1.1em;
        }
    
        .sermon-date,
        .sermon-passage {
            font-size: 0.85em;
        }
    
        .watch-button {
            font-size: 0.9em;
            padding: 8px 16px;
        }
    }
    
    @media (max-width: 280px) {
        .sermon-image {
            max-width: 100px;
        }
    
        .sermon-title {
            font-size: 1em;
        }
    
        .sermon-date,
        .sermon-passage {
            font-size: 0.8em;
        }
    
        .watch-button {
            font-size: 0.85em;
            padding: 6px 12px;
        }
    }
    .search-bar {
        margin: 20px 0;
        padding: 10px;
        width: 100%;
        max-width: 400px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      
      .pagination {
        display: flex;
        justify-content: center;
        margin: 20px 0;
      }
      
      .page-number {
        margin: 0 5px;
        padding: 10px 15px;
        border: 1px solid #ccc;
        border-radius: 4px;
        cursor: pointer;
      }
      
      .page-number:hover {
        background-color: #f0f0f0;
      }