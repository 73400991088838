* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Arial", sans-serif;
}
.calvary-events {
  padding-top: 0px;
}
.calvary-events .events-hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content to the center */
  position: relative;
  color: #fff;
  text-align: center;
}
.events-hero-content {
  z-index: 2;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem; /* Add margin to separate from buttons */
  padding: 0 1rem; /* Add padding for smaller screens */
  flex: 1; /* Ensure it takes up available space */
  display: flex; /* Use flex to center text */
  flex-direction: column; /* Ensure vertical alignment */
  justify-content: center; /* Center text vertically */
}
.events-hero-buttons {
  z-index: 2;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
  justify-content: center; /* Align buttons to the right */
}
.btn {
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}
@media (max-width: 768px) {
  .events-hero-content {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .events-hero-buttons {
    gap: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
  }
  .events-hero-buttons .btn {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
  .events-hero{
    padding: 1rem;
  }
}
@media (max-width: 480px) {
  .events-hero-content {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .events-hero-buttons {
    gap: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
  }
  .events-hero-buttons .btn {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
  .events-hero{
    padding: 1rem;
}
}
.events-calendar h2 {
  font-size: 2rem;
  color: #d18900;
  margin-bottom: 1rem;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
