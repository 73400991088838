* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
  }
  
  .Giving {
    padding-top: 0px;
  }
  .Giving .hero {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    text-align: center;
    padding: 2rem;
  }
  .Giving .hero-content {
    z-index: 2;
    text-align: center;
    font-size: 3rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 768px) {
    .Giving .hero-content {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .Giving .hero-content {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  
  .how-to-give h2 {
    font-size: 2rem;
    color: #D18900;
    margin-bottom: 1rem;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.payment-methods {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 4rem;
}

.payment-option {
    background-color: #f7f1e3;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    display: flex;
    align-items: center;
    width: 568px;
    height: 159px;
    margin-left: 2rem;
    text-decoration: none;
}


.payment-option img {
    width: 159px;
    height: 159px;
    margin-right: 1rem;
}

.payment-option p {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    color: #D18900;
    margin-left: 2rem;
}
@media (max-width: 768px) {
  .payment-methods {
      grid-template-columns: 1fr;
  }

  .payment-option {
      flex-direction: column;
      align-items: center;
      width: auto;
      height: auto;
      margin-left: 0;
      padding: 1rem;
  }

  .payment-option img {
      margin-right: 0;
      margin-bottom: 1rem;
      width: 100px;
      height: 100px;
  }

  .payment-option p {
      font-size: 24px;
      margin-left: 0;
  }
}